'use client';

// components
import { SplashScreen } from '@/components/minimal/loading-screen';

// ----------------------------------------------------------------------

export default function Loading() {
  return <SplashScreen />;
}
